import styled from 'styled-components';

export const Wrapper = styled.section`
  height: auto;
  background: #f0f2f9;
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: space-evenly;
  padding: 50px;

  @media (max-width: 901px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  @media (max-width: 516px) {
    grid-row-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }

  > ul {
    > li {
      margin-bottom: 5px;

      .link {
        text-decoration: none;
        color: #044D75;
        opacity: 70%;

        &:hover {
          color: #489be8;
        }
      }
    }
  }

  .title {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #044D75;
  }

  .address {
    color: #234577;
    opacity: 70%;
  }

  #socials {
    display: flex;

    > a {
      padding-right: 8px;
      font-size: 0px;

      #facebook {
        width: 25px;
        height: 25px;
        border-radius: 0px;
      }
      
      #instagram {
        width: 25px;
        height: 25px;
        border-radius: 0px;
      }

      #linkedin {
        width: 25px;
        height: 25px;
        border-radius: 0px;
      }
    }
  }

  .cubo {
    > a {
      #logo-cubo {
        height: 120px;
        width: 120px;
      }
    }
  }
`;

export const SecondFooter = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #f0f2f9;
  height: 100%;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 363px) {
    text-align: center;
  }
  p {
    color: #234577;
    opacity: 70%;
  }
  svg {
    margin: 0 5px;
  }
  h2 {
    color: #234577;
  }
`;