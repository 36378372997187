import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 20px 100px;
  background: #FAF9FD;
  align-items: center;
  height: auto;
  border-radius: 8px;

  @media (max-width: 700px) {
    padding: 20px;
  }
`;

export const Container = styled.div`
  border-radius: 8px;
  width: auto;
  height: auto;
  box-shadow: 3px 3px rgba(26,130,226,.1);
  border: 1px solid rgba(26,130,226,.1);
  background: #fff;
`;

export const Title = styled.h2`
  padding: 10px 0;
  text-align: center;
  font-size: 50px;
  font-family: 'Eurostile', sans-serif;
  font-style: italic;
  font-weight: bold;
  color: #592c82;
  margin-bottom: 10px;
  background: #FAF9FD;

  @media (max-width: 500px) {
    font-size: 30px;
  }

  > span {
    color: #ff4713;
  }
`;

