import React from 'react';
import Slider from 'infinite-react-carousel';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { 
  Wrapper,
  Container,
  Title,
} from './styles';

const Carriers = () => {
  const data = useStaticQuery(graphql`
    query {
      jadlog:file(relativePath: { eq: "images/partners/jadlog-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jamef:file(relativePath: { eq: "images/partners/jamef-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      latam:file(relativePath: { eq: "images/partners/latam-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      totalexpress:file(relativePath: { eq: "images/partners/totalexpress-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gollog:file(relativePath: { eq: "images/partners/gollog-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transfolha:file(relativePath: { eq: "images/partners/transfolha-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      riberbrasil:file(relativePath: { eq: "images/partners/riberbrasilexpress-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      azulcargo:file(relativePath: { eq: "images/partners/azulcargo-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brasspress:file(relativePath: { eq: "images/partners/latam-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      buslog:file(relativePath: { eq: "images/partners/buslog-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      correios:file(relativePath: { eq: "images/partners/correios-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fm:file(relativePath: { eq: "images/partners/fm-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      plimor:file(relativePath: { eq: "images/partners/plimor-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tnt:file(relativePath: { eq: "images/partners/tnt-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transexpress:file(relativePath: { eq: "images/partners/transexpress-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transwells:file(relativePath: { eq: "images/partners/transwells-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      convertize:file(relativePath: { eq: "images/partners/convertize-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bling:file(relativePath: { eq: "images/partners/bling-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vtex:file(relativePath: { eq: "images/partners/vtex-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      irroba:file(relativePath: { eq: "images/partners/irroba-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shoppub:file(relativePath: { eq: "images/partners/shoppub-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      woocommerce:file(relativePath: { eq: "images/partners/woocommerce-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      magento:file(relativePath: { eq: "images/partners/magento-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      opencart:file(relativePath: { eq: "images/partners/opencart-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lojaintegrada:file(relativePath: { eq: "images/partners/lojaintegrada-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tinyerp:file(relativePath: { eq: "images/partners/tinyerp-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      xtech:file(relativePath: { eq: "images/partners/xtech-90x80@2x.jpg" }) {
        childImageSharp {
          fluid(jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Wrapper>
      <Title>AS MELHORES <span>TRANSPORTADORAS</span></Title>

        <Container>

          <Slider
            overScan={2} 
            slidesPerRow={4} 
            autoplay={true} 
            arrows={true} 
            arrowsBlock={false} 
            dots={false} 
            duration={4000} 
            autoplaySpeed={4000}
          >
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.jadlog.childImageSharp.fluid}
                alt="Logomarca da transportadora Jadlog parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.jamef.childImageSharp.fluid}
                alt="Logomarca da transportadora Jamef parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.latam.childImageSharp.fluid}
                alt="Logomarca da transportadora Latam Cargo parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.totalexpress.childImageSharp.fluid}
                alt="Logomarca da transportadora Total Express parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.gollog.childImageSharp.fluid}
                alt="Logomarca da transportadora Gol Log parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.riberbrasil.childImageSharp.fluid}
                alt="Logomarca da transportadora Riberbrasil parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.transfolha.childImageSharp.fluid}
                alt="Logomarca da transportadora Transfolha parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.azulcargo.childImageSharp.fluid}
                alt="Logomarca da transportadora Azul Cargo parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.brasspress.childImageSharp.fluid}
                alt="Logomarca da transportadora Brasspress parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.buslog.childImageSharp.fluid}
                alt="Logomarca da transportadora Buslog parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.correios.childImageSharp.fluid}
                alt="Logomarca da transportadora Correios parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.fm.childImageSharp.fluid}
                alt="Logomarca da transportadora FM parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.plimor.childImageSharp.fluid}
                alt="Logomarca da transportadora Plimor parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.tnt.childImageSharp.fluid}
                alt="Logomarca da transportadora TNT parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.transexpress.childImageSharp.fluid}
                alt="Logomarca da transportadora Trans Express parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.transwells.childImageSharp.fluid}
                alt="Logomarca da transportadora Transwells parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.convertize.childImageSharp.fluid}
                alt="Logomarca da plataforma Convertize parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.bling.childImageSharp.fluid}
                alt="Logomarca da plataforma Bling parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.vtex.childImageSharp.fluid}
                alt="Logomarca da plataforma Vtex parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.shoppub.childImageSharp.fluid}
                alt="Logomarca da plataforma Shoppub parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.irroba.childImageSharp.fluid}
                alt="Logomarca da plataforma Irroba parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.tinyerp.childImageSharp.fluid}
                alt="Logomarca da plataforma TinyERP parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.woocommerce.childImageSharp.fluid}
                alt="Logomarca da plataforma Woocommerce parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.magento.childImageSharp.fluid}
                alt="Logomarca da plataforma Magento parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.opencart.childImageSharp.fluid}
                alt="Logomarca da plataforma OpenCart parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.lojaintegrada.childImageSharp.fluid}
                alt="Logomarca da plataforma Loja Integrada parceira SmartEnvios"
              />
            </div>
            <div className="logos">
              <Img style={{maxHeight: '150px'}} fluid={data.xtech.childImageSharp.fluid}
                alt="Logomarca da plataforma Xtech parceira SmartEnvios"
              />
            </div>       
          </Slider>
        </Container>
        
      </Wrapper>
    </>
  );
}

export default Carriers;