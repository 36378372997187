import React, { useState } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import Smart from '../../assets/images/smart-logo.svg';

import { 
  Wrapper,
  HeaderContent,
  HeaderMenu,
  MobileMenu,
  MenuWrapper,
  Ul,
  ListMenu,
} from './styles';

const Header = () => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Wrapper id="wrapper">

        <HeaderContent>
          <img 
            id="smart-logo" 
            alt="Icone da SmartEnvios ao lado esquerdo do menu de opções"
            src="https://smartenvios.s3.sa-east-1.amazonaws.com/logo/svg/logo-horizontal-fundo-claro.svg"
          />
        </HeaderContent>

        <HeaderContent>
          <a 
            href="http://smartenvios.com"
            target="_blank"
            rel="noreferrer"
          >
            Ir para o Site
          </a>
          <HeaderMenu onClick={() => scrollTo('#beneficios')}>Benefícios</HeaderMenu>
          <HeaderMenu onClick={() => scrollTo('#contato')}>Contato</HeaderMenu>
        </HeaderContent>

        <MobileMenu open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </MobileMenu>

      </Wrapper>

      <MenuWrapper open={open}>
        <Ul>
          <a 
            href="http://smartenvios.com"
            target="_blank"
            rel="noreferrer"
          >
            Ir para o Site
          </a>
          <ListMenu onClick={() => scrollTo('#beneficios')}>Benefícios</ListMenu>
          <ListMenu onClick={() => scrollTo('#contato')}>Contato</ListMenu>
        </Ul>
      </MenuWrapper>
    </>
  );
};

export default Header;