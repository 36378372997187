import React from 'react';

import { 
  Wrapper, 
  Title,
  CardContainer,
  CardContent, 
  Text,
  Green, 
  GreenBar, 
  Yellow,
  YellowBar,
  Orange,
  OrangeBar,
  Blue,
  BlueBar,
  Arrow1,
  Arrow2,
  Arrow3,
} from './styles';

const Cards = () => {
  return (
    <>
      <Title id="beneficios">Você no controle<span><br />de tudo!</span></Title>
      <Wrapper>

        <CardContainer>

          <CardContent>
            <Green />
            <GreenBar />
            <Text>Na tela da SmartEnvios você digita: cep, peso, volume</Text>
          </CardContent>

          <Arrow1 />

          <CardContent>
            <Yellow />
            <YellowBar />
            <Text>Compara e escolhe a melhor relação custo x benefício</Text>
          </CardContent>

          <Arrow2 />

          <CardContent>
            <Orange />
            <OrangeBar />
            <Text>A plataforma chama o coletor para levar para a transportadora</Text>
          </CardContent>

          <Arrow3 />

          <CardContent>
            <Blue />
            <BlueBar />
            <Text>E com o rastreio, você acompanha cada etapa até a entrega ao consumidor</Text>
          </CardContent>

        </CardContainer>
        
      </Wrapper>
    </>
  );
}

export default Cards;