import React, { useState } from 'react';

import { Wrapper, Container, ContactHeader, FormWrapper } from './styles';
import swal from 'sweetalert';
import Box from '../../assets/images/caixa.svg';

const API_GATEWAY_URL = process.env.GATSBY_API_GATEWAY;

export default function SmartContact() {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');

  async function onSubmit(e) {
    e.preventDefault();

    const data = {
      name,
      company,
      email,
      phone,
      city
    };

    try {
      await fetch(API_GATEWAY_URL, {
        method: "POST",
        mode: "no-cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });
      swal("Contato solicitado com sucesso!", "Aguarde nosso retorno!", "success");

      setName('');
      setPhone('');
      setEmail('');
      setCompany('');
      setCity('');
    } catch (error) {
      swal("Ops... ocorreu uma falha", "Tente novamente", "error");
    }
  };

  return (
    <Wrapper id="contato">
      <Container>
        
          <ContactHeader>
            <span>Legal!</span> Agora que você já conhece a plataforma <span className="Eurostile">SMARTENVIOS</span> o que acha de conversar um pouco com nossos especialistas em logística e frete?
          </ContactHeader>

          <FormWrapper>
            <form onSubmit={onSubmit} method="post">

              <label htmlFor="name">
                <p>Nome</p>
                <input
                  required
                  placeholder="Digite o seu nome"
                  name="name" 
                  type="text"
                  id="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </label>

              <label htmlFor="company">
                <p>Empresa</p>
                <input
                  required
                  type="text"
                  name="company"
                  id="company"
                  placeholder="Digite o nome da sua empresa"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />
              </label>

              <label htmlFor="email">
                <p>Email</p>
                <input
                  required
                  placeholder="Digite o seu e-mail"
                  name="email"
                  type="email"
                  id="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </label>

              <label htmlFor="city">
                <p>Cidade</p>
                <input
                  required
                  type="text"
                  name="city"
                  id="city"
                  placeholder="Digite o nome da sua cidade"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
              </label>

              <label htmlFor="phone">
                <p>Telefone</p>
                <input 
                  required
                  name="phone"
                  placeholder="Digite o seu telefone"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />

                <Box id="caixa"/>
              </label>

                <button type="submit" className="rounded-lg shadow-lg">
                  Solicitar Contato
                </button>
            </form>
          </FormWrapper>

      </Container>
    </Wrapper>
  );
};