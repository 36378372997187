import styled from 'styled-components';

export const Wrapper = styled.section`
  
  > a {
    .float {
      position:fixed;
      width: 50px;
      height: 50px;
      bottom: 60px;
      right: 20px;
      background-color:#25d366;
      color:#FFF;
      border-radius:50px;
      text-align:center;
      align-self: center;
      box-shadow: 2px 2px 3px #999;
      z-index:100;
    }
  }

  @media (max-width: 900px) {
    a {
      right: 0;
    }
  }
`;