import React from 'react';

import { Wrapper, Container, Title } from './styles';

const VideoPlayer = () => {
  return (
    <Wrapper>
      
      <Container>
      <Title>VEJA NOSSA APRESENTAÇÃO EM VÍDEO</Title>
      
        <iframe
          title="Smart"
          style={{borderRadius: '6px'}} 
          className="youtube"
          height="350px"
          width="700px"
          src="https://www.youtube.com/embed/1FvxhNa89IY?rel=0&modestBranding=1&showInfo=0&controls=0" 
          frameBorder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        />
      </Container>
      
    </Wrapper>
  );
}

export default VideoPlayer;