import styled from 'styled-components';
import Hand from '../../assets/images/hand.svg';

export const Wrapper = styled.div`
  padding: 20px 100px;
  background: #FAF9FD;

  @media (max-width: 620px) {
    padding: 20px;
  }
`;

export const HandLogo = styled(Hand)`
  max-width: 400px;
  max-height: 400px;
  width: auto;
  height: auto;

  @media (max-width: 450px) {
    width: 200px;
    height: 200px;
  }
`;

export const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  box-shadow: 3px 3px rgba(26,130,226,.1);
  border: 1px solid rgba(26,130,226,.1);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  > p {
    font-family: 'Quicksand', sans-serif;
    color: #999;
    width: 50%;
    line-height: 22px;

    @media (max-width: 1000px) {
      width: 90%;
    }

    > span {
      font-family: 'Eurostile', sans-serif;
      font-weight: bold;
      font-style: italic;
      font-size: 18px;
      color: #592c82;
    }

    .envios {
      font-family: 'Eurostile', sans-serif;
      font-weight: bold;
      font-style: italic;
      font-size: 18px;
      color: #ff4713;
    }
  }
`;
