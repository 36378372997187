import React from 'react';
import { Link } from 'gatsby';

import Facebook from '../../assets/images/facebook.svg';
import Instagram from '../../assets/images/instagram.svg';
import LinkedIn from '../../assets/images/linkedin.svg';
import Cubo from '../../assets/images/cubo.svg';
import Brazil from '../../assets/images/brazil.svg';

import { 
  Wrapper,
  Navigation, 
  SecondFooter, 
  FooterContent 
} from './styles';

export default function SmartFooter() {
  return (
    <>
      <Wrapper>
        <Navigation>
          <ul>
            <li className="title">SmartEnvios</li>

            <li>
              <Link className="link" to="/#wrapper">Home</Link>
            </li>

            <li>
              <Link className="link" to="/#beneficios">Benefícios</Link>
            </li>

            <li>
              <Link className="link" to="/#contato">Contato</Link>
            </li>

            <li>
              <a 
                className="link" 
                href="https://smartenvios.com/" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                  Ir para o site
              </a>
            </li>
          </ul>

          <div className="cubo">
            <a href="https://cubo.network/startups/smartenvios" alt="Cubo" target="_blank" rel="noreferrer">
              <Cubo id="logo-cubo"/>
            </a>
          </div>
          
          <ul>
            <p className="title">Redes Sociais</p>
            <div id="socials">
              <a href="https://www.facebook.com/enviossmart/" target="_blank" rel="noopener noreferrer" ><Facebook id="facebook" />Facebook</a>
              <a href="https://www.instagram.com/smartenvios/" target="_blank" rel="noopener noreferrer" ><Instagram id="instagram" />Instagram</a>
              <a href="https://www.linkedin.com/company/smartenvios/" target="_blank" rel="noopener noreferrer" ><LinkedIn id="linkedin"/>Linkedin</a>
            </div>
          </ul>

        </Navigation>


      </Wrapper>
      <SecondFooter>
        <FooterContent>
          <p>Feito com</p><Brazil/><p>no Brasil</p>
        </FooterContent>
        <FooterContent>
          <h2>SmartEnvios - Todos os Direitos Reservados</h2>
        </FooterContent>
      </SecondFooter>
    </>
  );
};