import styled from 'styled-components';
import GreenCard from '../../assets/images/green-card.svg';
import GreenBorder from '../../assets/images/green-bar.svg';
import YellowCard from '../../assets/images/yellow-card.svg';
import YellowBorder from '../../assets/images/yellow-bar.svg';
import OrangeCard from '../../assets/images/orange-card.svg';
import OrangeBorder from '../../assets/images/orange-bar.svg';
import BlueCard from '../../assets/images/blue-card.svg';
import BlueBorder from '../../assets/images/blue-bar.svg';
import ArrowRight from '../../assets/images/arrow-right.svg';
import Separator from '../../assets/images/footer-bar.svg';

export const Wrapper = styled.section`
  width: 100%;
  padding: 20px;
  height: auto;
  background: #FAF9FD;
`;

export const Title = styled.h1`
  align-items: center;
  text-align: center;
  font-family: 'Eurostile', sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: #592c82;
  height: auto;
  background: #FAF9FD;

  @media (max-width: 500px) {
    font-size: 50px
  }

  > span {
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    color: #ff4713;
  }
`;

export const CardContainer = styled.section`
  padding: 20px;
  border: 1px solid rgba(26,130,226,.1);
  box-shadow: 3px 3px rgba(26,130,226,.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  height: auto;
  position: relative;
  background: #fff;

  @media (max-width: 1240px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: auto;
  align-items: center;
`;

export const Text = styled.h3`
  text-align: center;
  font-size: 17px;
  font-family: 'Quicksand', sans-serif;
  color: #592c82;
  padding: 10px;
  margin-bottom: 20px;
`;

export const Arrow1 = styled(ArrowRight)`
  position: absolute;
  height: auto;
  width: auto;
  left: 284px;
  top: 130px;
  bottom: 130px;

  @media (max-width: 1920px) {
    left: 420px;
  }

  @media (max-width: 1720px) {
    left: 380px;
  }

  @media (max-width: 1600px) {
    left: 340px;
  }

  @media (max-width: 1500px) {
    left: 320px;
  }

  @media (max-width: 1440px) {
    left: 310px;
  }

  @media (max-width: 1400px) {
    left: 295px;
  }

  @media (max-width: 1366px) {
    left: 283px;
  }

  @media (max-width: 1348px) {
    width: 100px;
  }

  @media (max-width: 1316px) {
    width: 90px;
  }

  @media (max-width: 1292px) {
    left: 275px;
  }

  @media (max-width: 1268px) {
    left: 270px;
  }

  @media (max-width: 1260px) {
    left: 265px;
  }

  @media (max-width: 1250px) {
    left: 260px;
  }

  @media (max-width: 1240px) {
    display: none;
  }
`;

export const Arrow2 = styled(ArrowRight)`
  position: absolute;
  height: auto;
  width: auto;
  top: 130px;
  bottom: 130px;

  @media (max-width: 1336px) {
    width: 90px;
  }

  @media (max-width: 1240px) {
    display: none;
  }
`;

export const Arrow3 = styled(ArrowRight)`
  position: absolute;
  height: auto;
  width: auto;
  right: 282px;
  top: 130px;
  bottom: 130px;

  @media (max-width: 1920px) {
    right: 420px;
  }

  @media (max-width: 1720px) {
    right: 380px;
  }

  @media (max-width: 1600px) {
    right: 340px;
  }

  @media (max-width: 1500px) {
    right: 320px;
  }

  @media (max-width: 1440px) {
    right: 310px;
  }

  @media (max-width: 1400px) {
    right: 295px;
  }

  @media (max-width: 1366px) {
    right: 283px;
  }

  @media (max-width: 1348px) {
    width: 100px;
  }

  @media (max-width: 1316px) {
    width: 90px;
  }

  @media (max-width: 1292px) {
    right: 275px;
  }

  @media (max-width: 1268px) {
    right: 267px;
  }

  @media (max-width: 1260px) {
    right: 263px;
  }

  @media (max-width: 1250px) {
    right: 260px;
  }

  @media (max-width: 1240px) {
    display: none;
  }
`;

export const Green = styled(GreenCard)`
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
`;

export const GreenBar = styled(GreenBorder)`
  margin-top: 20px;
`;

export const Yellow = styled(YellowCard)`
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
`;

export const YellowBar = styled(YellowBorder)`
  margin-top: 20px;
`;

export const Orange = styled(OrangeCard)`
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
`;

export const OrangeBar = styled(OrangeBorder)`
  margin-top: 20px;
`;

export const Blue = styled(BlueCard)`
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
`;

export const BlueBar = styled(BlueBorder)`
  margin-top: 20px;
`;

export const Bar = styled(Separator)`
  width: 100%;
  padding: 10px 20px;
`;