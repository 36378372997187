import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #FAF9FD;
  padding: 20px 100px;

  @media (max-width: 620px) {
    padding: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 35px;
  margin-bottom: 20px;
  font-family: 'Eurostile', sans-serif;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: #2A5072;

  @media (max-width: 520px) {
    font-size: 25px;
  }

  @media (max-width: 410px) {
    font-size: 14px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 3px 3px rgba(26,130,226,.1);
  border: 1px solid rgba(26,130,226,.1);
  background: #fff;
  width: auto;

  .youtube {
    border-radius: 6px;

    @media (max-width: 920px) {
      width: 600px;
    }

    @media (max-width: 820px) {
      width: 400px;
      height: 250px;
    }

    @media (max-width: 450px) {
      width: 350px;
      height: 200px;
    }

    @media (max-width: 400px) {
      width: 250px;
      height: 150px;
    }
    
  }
`;