import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 20px 100px;
  background: #FAF9FD;

  @media (max-width: 1023px) {
    padding: 20px;
  }
`;

export const Container = styled.div`
  background: #F4F6F9;
  border-radius: 8px;
  padding: 30px 60px 30px 40px;
  box-shadow: 3px 3px rgba(26,130,226,.1);
  border: 1px solid rgba(26,130,226,.1);
  position: relative;
`;

export const ContactHeader = styled.h2`
  font-size: 20px;
  color: #234577;
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 20px;

  > span {
    color: #ff4713;
    font-weight: bold;
  }

  > span.Eurostile {
    font-weight: 600;
    font-style: italic;
    font-family: 'Eurostile', sans-serif;
    color: #234577;
  }
`;

export const FormWrapper = styled.section`
  width: auto;

  > form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 13px;

    > button {
      height: 45px;
      width: 360px;
      border-radius: 6px;
      background: #ff4713;
      color: #fff;
      font-family: 'Quicksand', sans-serif;
      font-weight: bold;
      font-size: 14px;
      box-shadow: 3px 3px rgba(26,130,226,.1);
      transition: background;
      border: none;
      cursor: pointer;
      margin-top: 12px;
      outline: none;

      &:hover {
        background: #2A5072;
      }

      @media (max-width: 656px) {
          width: 70vw;
        }
    }
    
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 1110px) {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 1023px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 13px;
    }

    @media (max-width: 950px) {
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    > label {
      > p {
        font-size: 16px;
        font-weight: bold;
        color: #044D75;
      }

      > input {
        text-align: left;
        width: 360px;
        height: 40px;
        border-radius: 6px;
        padding-left: 10px;
        box-shadow: 3px 3px rgba(26,130,226,.1);
        border: none;
        font-family: 'Quicksand', sans-serif;
        outline: none;
        color: #999;

        @media (max-width: 656px) {
          width: 70vw;
        }

        ::-webkit-input-placeholder { 
          color:#044D75;
          font-size: 13px;
          text-align: left;

          &:active {
          outline: none;
        }
        }

        ::-moz-placeholder { /* Mozilla Firefox 19+ */
          color:#044D75;
          font-size: 13px;
          text-align: left;

          &:active {
          outline: none;
        }
        }
      }

      > input#phone {
        width: 100%;

        @media (max-width: 1455px) {
          width: 145px;
        }

        @media (max-width: 1360px) {
          width: 125px;
        }

        @media (max-width: 1023px) {
          width: 145px;
        }

        @media (max-width: 550px) {
          width: 170px;
        }

        @media (max-width: 460px) {
          width: 315px;
        }

        @media (max-width: 420px) {
          width: 260px;
        }

        @media (max-width: 325px) {
          width: 200px;
        }
      }
      
      #caixa {
        position: absolute;
        bottom: 0;
        right: 0;

        @media (max-width: 1200px) {
          width: 500px;
          height: 400px;
        }

        @media (max-width: 1110px) {
          width: 400px;
          height: 300px;
        }

        @media (max-width: 1023px) {
          display: none;
        }

        @media (max-width: 950px) {
          display: initial;
        }

        @media (max-width: 860px) {
          width: 300px;
          height: 200px;
        }

        @media (max-width: 720px) {
          width: 200px;
          height: 200px;
        }

        @media (max-width: 656px) {
          display: none;
        }
      }
    }

    
  }
`;