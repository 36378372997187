import React from 'react';

import { Wrapper, Container, HandLogo } from './styles';

const Smart = () => {
  return (
    <Wrapper>

      <Container>
        <HandLogo />
        <p>
          <span>SMART</span><span className="envios">ENVIOS</span> é o sistema mais eficiente para levar sua mercadoria ao seu
          cliente. Em uma única plataforma você tem acesso às melhores 
          transportadoras do Brasil pelo menor prazo e o melhor preço, usando todos 
          os recursos da tecnologia a seu favor.
          <br />É tudo tão simples com a <span>SMART</span><span className="envios">ENVIOS</span> que 
          na mesma tela você escolhe a melhor opção em questão de segundos e já 
          manda retirar sua encomenda para entrega. 
        </p>

      </Container>
      
    </Wrapper>
  );
}

export default Smart;