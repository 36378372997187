import React from 'react';

import WhatsApp from '../../assets/images/whatsapp.svg';

import { Wrapper } from './styles';

export default function WhatsAppButton() {
  return (
    <Wrapper>
      <a href="https://wa.me/5516997835014?text=Ol%C3%A1!%20Eu%20gostaria%20de%20saber%20mais" target="_blank" rel="noreferrer">
        <WhatsApp 
          className="float" 
          alt="Botão flutuante com ícone do whatsapp ao lado direito inferior da página"
        />
      </a>
    </Wrapper>
  );
}
