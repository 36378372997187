import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    background: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(26,130,226,.1);
    justify-content: space-between;
    padding: 0px 40px;

    @media (max-width: 960px) {
      position: initial;
    }

    @media (max-width: 810px) {
      padding: 0px 10px;
    }

    @media (max-width: 280px) {
      display: flex;
      flex-direction: column;
    }
`;

export const HeaderContent = styled.div`
  align-items: center;

  #smart-logo {
    height: 80px;
    width: 250px;
  }

  > a {
    text-decoration: none;
    font-size: 14px;
    color: #2A5072;
    font-weight: bold;
    padding: 0px 14px;
    transition: color 0.2s;

    &:hover {
      color: #489be8;
    }

    @media (max-width: 772px) {
      display: none;
    }
  }
`;

export const HeaderMenu = styled.button`
  text-decoration: none;
  font-size: 14px;
  color: #2A5072;
  font-weight: bold;
  padding: 0px 14px;
  transition: color 0.2s;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #489be8;
  }

  @media (max-width: 772px) {
    display: none;
  }
`;

export const MobileMenu = styled.section`
  width: 2rem;
  height: 1.75rem;
  cursor: pointer;
  
  @media (min-width: 773px) {
    display: none;
  }

  > div {
    width: 2rem;
    height: 5px;
    transition: background 0.5s ease-out;
    background: ${({ open }) => open ? '#2A5072' : '#ff4713'};
    border-radius: 10px;
    transform-origin: 1px;
  }

  > div + div {
    margin: 4px 0;
  }

  @media (max-width: 772px) {
    display: initial;
  }

  @media (max-width: 280px) {
    margin-bottom: 10px;
  }
`;

export const MenuWrapper = styled.section`
  position: absolute;
  transform: translateX(-250px);

  @media (max-width: 772px) {
    line-height: 30px;
    transition: all .5s ease-out;
    transform: ${({ open }) => open ? 'translateX(-250px)' : 'translateX(0)'};
    position: ${({ open }) => open ? 'absolute' : 'initial'};
  }
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  
  > a {
    text-decoration: none;
    border-bottom: ${({ open }) => open ? '0px solid #eee' : '1px solid #eee'};
    color: #2A5072;

    &:hover {
      color: #489be8;
    }
  }
`;

export const ListMenu = styled.div`
  text-decoration: none;
  border-bottom: ${({ open }) => open ? '0px solid #eee' : '1px solid #eee'};
  color: #2A5072;
  cursor: pointer;

  &:hover {
    color: #489be8;
  }
`;
