import React from "react"
import { Helmet } from 'react-helmet';

import Header from '../components/Header';
import VideoPlayer from '../components/VideoPlayer';
import Smart from '../components/Smart';
import Cards from '../components/Cards';
import Carriers from '../components/Carriers';
import SmartContact from '../components/SmartContact';
import SmartFooter from '../components/SmartFooter';
import WhatsApp from '../components/WhatsAppButton/WhatsAppButton';

import GlobalStyles from '../styles/GlobalStyles';

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SmartEnvios - Muito mais que um gateway de frete </title>
        <link rel="canonical" href='https://smartenvios.com' />
      </Helmet>
      <Header />
      <VideoPlayer />
      <Smart />
      <Cards />
      <Carriers />
      <SmartContact />
      <SmartFooter />
      <WhatsApp />
      <GlobalStyles />
    </>
  );
}
